<template>
	<div class="main-content">
		<filter-modal :filter="filter" :show="is.showing.filterModal" @on-filter="onFilter" @close="closeFilterModal" />
		<payment-instruction-modal
			:disburse="disburse"
			:payment-method-options="paymentMethodOptions"
			:show="is.showing.paymentInstruction"
			@close="closePaymentInstructionModal()"
		/>
		<vue-html2pdf
			ref="html2Pdf"
			:enable-download="true"
			:filename="`${$t('Premium Points Disburse', 'Premium Points Disburse')} (${disburseId})`"
			:float-layout="true"
			:manual-pagination="false"
			:paginate-elements-by-height="1400"
			:pdf-quality="2"
			:preview-modal="false"
			:show-layout="false"
			pdf-content-width="100%"
			pdf-format="a4"
			pdf-orientation="portrait"
		>
			<section slot="pdf-content">
				<invoice v-if="disburseDetail && payment && disburseId" :disburse-id="disburseId" :disburse-detail="disburseDetail" :payment="payment" />
			</section>
		</vue-html2pdf>
		<add-modal :show="is.showing.addModal" @close="closeAddModal" />
		<ktv-dialog
			v-model="is.showing.deleteDialog"
			:text="$t('Do you want to delete this data?', 'Do you want to delete this data?')"
			:title="$t('Delete Data', 'Delete Data')"
		>
			<template #footer>
				<ktv-button :text="$('Delete', 'Delete')" :loading="is.loading" @click="deleteData()" />
				<ktv-button :text="$('Cancel', 'Cancel')" color="light" :loading="is.loading" class="ml-2" @click="closeDeleteDialog()" />
			</template>
		</ktv-dialog>
		<ktv-breadcrumb
			:folder="$t('Premium Points', 'Premium Points')"
			:page="$t('Disburse', 'Disburse')"
			:title="$t('Premium Points Disburse', 'Premium Points Disburse')"
		/>
		<ktv-table
			:columns="columns"
			:filter="false"
			:is-loading="is.loading"
			:line-numbers="false"
			:rows="premiumPointList"
			:search-enabled="false"
			:search-placeholder="$t('Search by disburse id', 'Search by disburse id')"
			:total-rows="totalRows"
			min-height="250px"
			mode="remote"
			@on-page-change="onPageChange"
			@on-per-page-change="onPerPageChange"
			@on-search="onSearch"
			@on-sort-change="onSortChange"
		>
			<template #actionleft>
				<ktv-button v-if="isAllowedToAdd" :text="$t('Add', 'Add')" icon="add" @click="showAddModal" />
			</template>
			<template #actionright>
				<ktv-button class="ml-2" :text="$t('Filter', 'Filter')" icon="filter" color="light" @click="showFilterModal" />
			</template>
			<template #columns="{ props }">
				{{ $t(props.column.label, props.column.label) }}
			</template>
			<template #rows="{ props }">
				<span v-if="props.column.field === 'button'">
					<b-dropdown size="xs" variant="link" toggle-class="text-decoration-none p-0" no-caret>
						<template #button-content>
							<img :src="icons.dropdown" />
						</template>
						<b-dropdown-group id="dropdown-group-report" :header="$t('Action', 'Action')" class="pt-2">
							<b-dropdown-item v-if="isAllowedToViewDetail" class="dropdown-item--wider" @click="showDetail(props.row.premiumDisburseId)">
								<img :src="icons.view" class="imgicon" />{{ $t("Detail", "Detail") }}
							</b-dropdown-item>
							<b-dropdown-item
								v-if="isAllowedToViewPaymentInstruction"
								class="dropdown-item--wider"
								@click="showPaymentInstruction(props.row.premiumDisburseId)"
							>
								<img :src="icons.info" class="imgicon" />{{ $t("Show Payment Instruction", "Show Payment Instruction") }}
							</b-dropdown-item>
							<b-dropdown-item v-if="isAllowedToDownloadInvoice" class="dropdown-item--wider" @click="downloadInvoice(props.row)">
								<img :src="icons.download" class="imgicon" />{{ $t("Download Invoice", "Download Invoice") }}
							</b-dropdown-item>
							<b-dropdown-item v-if="isAllowedToDelete" class="dropdown-item--wider" @click="showDeleteDialog(props.row)">
								<img :src="icons.delete" class="imgicon" />{{ $t("Delete", "Delete") }}
							</b-dropdown-item>
						</b-dropdown-group>
						<b-dropdown-divider></b-dropdown-divider>
						<b-dropdown-group id="dropdown-group-report" :header="$t('Report', 'Report')">
							<b-dropdown-item
								class="dropdown-item--wider"
								@click="showReport({ disburseId: props.row.premiumDisburseId, type: 'Agri Input Handover' })"
							>
								<img :src="icons.report" class="imgicon" />{{ $t("Agri Input Handover Report", "Agri Input Handover Report") }}
							</b-dropdown-item>
							<b-dropdown-item
								class="dropdown-item--wider"
								@click="showReport({ disburseId: props.row.premiumDisburseId, type: 'CVC Transaction' })"
							>
								<img :src="icons.report" class="imgicon" />{{ $t("CVC Transaction Report", "CVC Transaction Report") }}
							</b-dropdown-item>
							<b-dropdown-item
								class="dropdown-item--wider"
								@click="showReport({ disburseId: props.row.premiumDisburseId, type: 'Premium Payment' })"
							>
								<img :src="icons.report" class="imgicon" />{{ $t("Premium Payment Report", "Premium Payment Report") }}
							</b-dropdown-item>
						</b-dropdown-group>
					</b-dropdown>
				</span>
				<span v-else-if="props.column.field === 'premiumDisburseId'">{{ props.row.premiumDisburseId ? props.row.premiumDisburseId : "-" }}</span>
				<span v-else-if="props.column.field === 'statusDesc'">{{ props.row.statusDesc ? props.row.statusDesc : "-" }}</span>
				<span v-else-if="props.column.field === 'siteName'">{{ props.row.siteName ? props.row.siteName : "-" }}</span>
				<span v-else-if="props.column.field === 'areaCode'">{{ props.row.areaCode ? props.row.areaCode : "-" }}</span>
				<span v-else-if="props.column.field === 'premiumStartDate'">{{ props.row.premiumStartDate ? props.row.premiumStartDate : "-" }}</span>
				<span v-else-if="props.column.field === 'premiumEndDate'">{{ props.row.premiumEndDate ? props.row.premiumEndDate : "-" }}</span>
				<span v-else-if="props.column.field === 'netto'">
					{{ props.row.netto && props.row.netto >= 0 ? idrFilter(props.row.netto) : "-" }}
				</span>
				<span v-else-if="props.column.field === 'totalPremiumAmount'">
					{{ props.row.totalPremiumAmount >= 0 ? IDRFilter(Math.round(props.row.totalPremiumAmount)) : "-" }}
				</span>
				<span v-else-if="props.column.field === 'totalPremiumPoint'">
					{{ props.row.totalPremiumAmount >= 0 ? idrFilter(Math.round(props.row.totalPremiumAmount)) : "-" }}
				</span>
				<span v-else-if="props.column.field === 'createdBy'">{{ props.row.createdBy ? props.row.createdBy : "-" }}</span>
				<span v-else-if="props.column.field === 'createdDate'">{{ props.row.createdDate ? props.row.createdDate : "-" }}</span>
			</template>
		</ktv-table>
	</div>
</template>
<script>
	import { each, findWhere, map } from "underscore"
	import { KtvButton, KtvDialog, KtvTable } from "@/components"
	import { mapActions, mapGetters, mapState } from "vuex"
	import AddModal from "./add/modals/_Add"
	import FilterModal from "./_Filter"
	import Invoice from "../view/_Invoice"
	import PaymentInstructionModal from "./_PaymentInstruction"
	import VueHtml2pdf from "vue-html2pdf"

	import deleteIcon from "@/assets/images/icon/icondelete.png"
	import downloadIcon from "@/assets/images/icon/download-gray.svg"
	import dropdownIcon from "@/assets/images/icon/icondropdown.png"
	import infoIcon from "@/assets/images/icon/info-gray.svg"
	import reportIcon from "@/assets/images/icon/report-gray.svg"
	import viewIcon from "@/assets/images/icon/iconview.png"

	const icons = {
		delete: deleteIcon,
		download: downloadIcon,
		dropdown: dropdownIcon,
		info: infoIcon,
		report: reportIcon,
		view: viewIcon,
	}

	export default {
		name: "PremiumPointsDisburseList",
		metaInfo: {
			title: "Disburse Premium Points",
		},
		components: { AddModal, FilterModal, Invoice, KtvButton, KtvDialog, KtvTable, PaymentInstructionModal, VueHtml2pdf },
		data() {
			return {
				columns: [
					{
						field: "button",
						html: true,
						sortable: false,
						tdClass: "text-center",
						thClass: "text-center",
						width: "50px",
					},
					{
						field: "premiumDisburseId",
						label: "ID",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "statusDesc",
						label: "Status",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
					{
						field: "siteName",
						label: "Site",
						thClass: "text-left",
						width: "200px",
					},
					{
						field: "areaCode",
						label: "Hub Area",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
					{
						field: "premiumStartDate",
						label: "Start Date",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "premiumEndDate",
						label: "End Date",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "netto",
						label: "Netto (kg)",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "totalPremiumAmount",
						label: "Premium",
						tdClass: "text-right",
						thClass: "text-right",
						width: "150px",
					},
					{
						field: "totalPremiumPoint",
						label: "Premium Points",
						tdClass: "text-right",
						thClass: "text-right",
						width: "150px",
					},
					{
						field: "createdBy",
						label: "Submitted By",
						tdClass: "text-center",
						thClass: "text-center",
						width: "200px",
					},
					{
						field: "createdDate",
						label: "Date Submitted",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
				],
				deletePremiumPointId: null,
				disburse: {
					accordionAttributes: [],
					accountName: "PT Koltiva", // TO-DO: Koltiva Name
					accountNumber: null,
					art23: 0,
					destinationId: "261", // TO-DO: Koltiva ID
					destinationName: "PT Koltiva", // TO-DO: Koltiva Name
					destinationType: "Koltiva",
					expiredDate: null,
					note: null,
					paymentInstructions: [],
					paymentMethodId: 2,
					subTotal: 0,
					totalPayment: 0,
					transactionId: null,
					vat: 0,
					virtualAccount: null,
				},
				disburseId: null,
				filter: {
					areaCode: null,
					date: null,
					siteName: null,
					status: null,
				},
				icons: icons,
				is: {
					loading: false,
					showing: {
						addModal: false,
						deleteDialog: false,
						filterModal: false,
						paymentInstruction: false,
					},
				},
				params: {
					limit: 10,
					order: null,
					page: 1,
					search: null,
				},
				payment: null,
				paymentMethodOptions: [],
			}
		},
		computed: {
			...mapGetters({
				premiumPointActions: "AUTHDATA/premiumPointActions",
				token: "AUTHDATA/token",
			}),
			...mapState("PAYMENT", ["paymentMethodList"]),
			...mapState("PREMIUMPOINT", ["disburseDetail", "premiumPointList", "totalRows"]),
			isAllowedToAdd() {
				return this.getActionPermission("add")
			},
			isAllowedToDelete() {
				return this.getActionPermission("delete")
			},
			isAllowedToDownloadInvoice() {
				return this.getActionPermission("download_invoice")
			},
			isAllowedToViewDetail() {
				return this.getActionPermission("detail")
			},
			isAllowedToViewPaymentInstruction() {
				return this.getActionPermission("view_payment_instruction")
			},
		},
		watch: {
			"paymentMethodList.premiumPoint": {
				deep: true,
				handler() {
					this.getPaymentMethodOptions()
				},
				immediate: true,
			},
			token: {
				handler(newValue, oldValue) {
					if (newValue && newValue !== oldValue) {
						this.getPaymentReference("premium-point").then(() => {
							this.getPaymentReference("premium-point")
						})
					}
				},
				immediate: true,
			},
		},
		created() {
			this.getData()
		},
		beforeDestroy() {
			this.disburse = {
				accordionAttributes: [],
				accountName: "PT Koltiva", // TO-DO: Koltiva Name
				accountNumber: null,
				art23: 0,
				destinationId: "261", // TO-DO: Koltiva ID
				destinationName: "PT Koltiva", // TO-DO: Koltiva Name
				destinationType: "Koltiva",
				expiredDate: null,
				note: null,
				paymentInstructions: [],
				paymentMethodId: 2,
				subTotal: 0,
				totalPayment: 0,
				transactionId: null,
				vat: 0,
				virtualAccount: null,
			}

			this.disburseId = null
			this.payment = null

			this.params = {
				limit: 10,
				order: null,
				page: 1,
				search: null,
			}
			this.resetDisburseDetail()
		},
		methods: {
			...mapActions({
				deletePremium: "PREMIUMPOINT/deletePremium",
				getDisburseDetail: "PREMIUMPOINT/getDisburseDetail",
				getPaymentReference: "PAYMENT/getPaymentReference",
				getPaymentStatus: "PAYMENT/getPaymentStatus",
				getPremiumPointList: "PREMIUMPOINT/getPremiumPointList",
				resetDisburseDetail: "PREMIUMPOINT/resetDisburseDetail",
			}),
			closeAddModal() {
				this.is.showing = {
					...this.is.showing,
					addModal: false,
				}
			},
			closeDeleteDialog() {
				this.deletePremiumPointId = null

				this.is.showing = {
					...this.is.showing,
					deleteDialog: false,
				}

				this.is = {
					...this.is,
					loading: false,
				}
			},
			closeFilterModal() {
				this.is.showing = {
					...this.is.showing,
					filterModal: false,
				}
			},
			closePaymentInstructionModal() {
				this.is.showing = {
					...this.is.showing,
					paymentInstruction: false,
				}
			},
			deleteData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.deletePremium(this.deletePremiumPointId).then(() => {
					this.closeDeleteDialog()
				})
			},
			downloadInvoice({ premiumDisburseId }) {
				this.is = {
					...this.is,
					loading: true,
				}

				this.disburseId = parseInt(String(premiumDisburseId).replaceAll("PRE", ""), 10) || null

				this.getDisburseDetail({
					disburseId: this.disburseId,
					limit: 10,
					page: 1,
				})
					.then(() => {
						this.getPaymentStatus({
							paymentMethodId: 2,
							type: "premium-point",
							uid: `${this.disburseId}-fee`,
						})
							.then((data) => {
								this.payment = Object.assign({}, data)

								this.$refs.html2Pdf.generatePdf()

								this.is = {
									...this.is,
									loading: false,
								}
							})
							.catch(() => {
								this.$swal(`${this.$t("Failed", "Failed")}!`, this.$t("Payment data not found", "Payment data not found"), "error")

								this.is = {
									...this.is,
									loading: false,
								}
							})
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
			getActionPermission(name = null) {
				return findWhere(this.premiumPointActions, { function: name })
			},
			getData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getPremiumPointList(this.params).then(() => {
					this.is = {
						...this.is,
						loading: false,
					}
				})
			},
			getPaymentMethodOptions() {
				this.paymentMethodOptions = map(this.paymentMethodList.premiumPoint, (paymentMethod) => {
					return {
						text: `${paymentMethod.PaymentLabel} - ${paymentMethod.PaymentMethod}`,
						value: parseInt(paymentMethod.PaymentMethodID, 10),
					}
				})
			},
			idrFilter(amount) {
				return this.$options.filters.Number(amount)
			},
			IDRFilter(amount) {
				return this.$options.filters.Rupiah(amount)
			},
			onFilter(filter) {
				this.filter = {
					...this.filter,
					...filter,
				}
				let searchParam = ""

				each(this.filter, (param, paramKey) => {
					if (param) {
						if (searchParam === "") {
							searchParam += `${paramKey}=${param}`
						} else {
							searchParam += `&${paramKey}=${param}`
						}
					}
				})

				this.updateParams({
					search: searchParam,
				})

				this.getData()
			},
			onPageChange({ currentPage }) {
				this.updateParams({ page: parseInt(currentPage, 10) })

				this.getData()
			},
			onPerPageChange({ currentPerPage }) {
				this.updateParams({ limit: currentPerPage, page: 1 })

				this.getData()
			},
			onSearch($event) {
				this.updateParams({ search: $event ? `id=${$event}` : null })

				this.getData()
			},
			onSortChange($event) {
				const { field, type } = $event[0]
				let orderQuery = null

				if (type !== "none") {
					orderQuery = "namaField=" + field + "&orderBy=" + type.toUpperCase()
				} else {
					orderQuery = null
				}

				this.updateParams({ order: orderQuery })

				this.getData()
			},
			showAddModal() {
				this.is.showing = {
					...this.is.showing,
					addModal: true,
				}
			},
			showDeleteDialog({ premiumDisburseId }) {
				this.deletePremiumPointId = parseInt(String(premiumDisburseId).replaceAll("PRE", ""), 10) || null

				this.is.showing = {
					...this.is.showing,
					deleteDialog: true,
				}
			},
			showDetail(premiumDisburseId) {
				this.$router.push({ name: "Premium Points Disburse Detail", params: { id: premiumDisburseId } })
			},
			showFilterModal() {
				this.is.showing = {
					...this.is.showing,
					filterModal: true,
				}
			},
			showPaymentInstruction(premiumDisburseId) {
				this.is = {
					...this.is,
					loading: true,
				}

				this.disburseId = parseInt(String(premiumDisburseId).replaceAll("PRE", ""), 10) || null
				this.disburse = {
					...this.disburse,
					disburseId: this.disburseId,
				}

				this.getDisburseDetail({
					disburseId: this.disburseId,
					limit: 10,
					page: 1,
				})
					.then(() => {
						this.getPaymentStatus({
							paymentMethodId: 2,
							type: "premium-point",
							uid: `${this.disburseId}-fee`,
						})
							.then(({ PaymentDetail }) => {
								this.disburse = {
									...this.disburse,
									accordionAttributes: map(PaymentDetail[0].PaymentInstruction, (_a, index) => {
										return {
											class: "i-Arrow-Down",
											"v-b-toggle": `'accordion-${index}'`,
										}
									}),
									art23: this.disburseDetail.whtArt23Fee,
									expiredDate: PaymentDetail[0].ExpiredDate,
									paymentInstructions: PaymentDetail[0].PaymentInstruction,
									subTotal: this.disburseDetail.subTotalFee,
									totalPayment: this.disburseDetail.totalPremiumFee,
									vat: this.disburseDetail.vatFee,
									virtualAccount: PaymentDetail[0].CompanyCode + PaymentDetail[0].VirtualAccount,
								}

								this.is.showing = {
									...this.is.showing,
									paymentInstruction: true,
								}
								this.is = {
									...this.is,
									loading: false,
								}
							})
							.catch(() => {
								this.$swal(`${this.$t("Failed", "Failed")}!`, this.$t("Payment data not found", "Payment data not found"), "error")

								this.is = {
									...this.is,
									loading: false,
								}
							})
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}
					})
			},
			showReport({ disburseId = null, type = null } = null) {
				this.$router.push({ name: `Premium Points ${type} Report`, params: { id: disburseId } })
			},
			updateParams(params) {
				this.params = { ...this.params, ...params }
			},
		},
	}
</script>

<style lang="sass" scoped>
	.dropdown-item--wider
		min-width: 17rem
</style>
