<template>
	<div class="main-content">
		<ktv-modal
			v-model="is.showingModal"
			:title="$t('Add Premium Points Disburse', 'Add Premium Points Disburse')"
			no-close-on-backdrop
			no-close-on-esc
			@close="close()"
		>
			<template #content>
				<validation-observer ref="form_add_disburse_premium_point" tag="form" @submit.prevent="save">
					<ktv-select v-model="localDisburse.districtId" rules="required" :options="districtOptions" :label="$t('District', 'District')" />
					<ktv-select v-model="localDisburse.unit" rules="required" :options="unitOptions" :label="$t('Unit', 'Unit')" />
					<ktv-select v-model="localDisburse.hubArea" rules="required" :options="hubAreaOptions" :label="$t('Hub Area', 'Hub Area')" />
					<ktv-input v-model="localDisburse.startDate" rules="required|date_format:yyyy-MM-dd" :label="$t('Start Date', 'Start Date')" type="date" />
					<ktv-input v-model="localDisburse.endDate" rules="required|date_format:yyyy-MM-dd" :label="$t('End Date', 'End Date')" type="date" />
				</validation-observer>
			</template>
			<template #footer>
				<div align="right">
					<ktv-button :text="$t('Reset', 'Reset')" color="light" :loading="is.loading" class="mr-2" @click="reset()" />
					<ktv-button :text="$t('Generate', 'Generate')" :disabled="is.loading" :loading="is.loading" @click="save()" />
				</div>
			</template>
		</ktv-modal>
	</div>
</template>
<script>
	import { findWhere, map, reject } from "underscore"
	import { KtvButton, KtvInput, KtvModal, KtvSelect } from "@/components"
	import { mapActions, mapState } from "vuex"
	import { ValidationObserver } from "vee-validate"

	export default {
		name: "PremiumPointsDisburseAdd",
		metaInfo: {
			title: "Disburse Premium Points",
		},
		components: { KtvButton, KtvInput, KtvModal, KtvSelect, ValidationObserver },
		props: {
			show: {
				default: false,
				required: true,
				type: Boolean,
			},
		},
		emits: ["close"],
		data() {
			return {
				districtOptions: [],
				hubAreaOptions: [],
				is: {
					loading: false,
					showingModal: false,
				},
				localDisburse: {
					districtId: null,
					endDate: null,
					hubArea: null,
					startDate: null,
					unit: null,
				},
				unitOptions: [],
			}
		},
		computed: {
			...mapState("OTHERS", ["districtList", "hubAreaList", "unitList"]),
			...mapState("PREMIUMPOINT", ["cvcHead"]),
		},
		watch: {
			districtList: {
				deep: true,
				handler() {
					this.getDistrictOptions()
				},
				immediate: true,
			},
			hubAreaList: {
				deep: true,
				handler() {
					this.getHubAreaOptions()
				},
				immediate: true,
			},
			"localDisburse.districtId": {
				handler(newValue, oldValue) {
					if (newValue && newValue !== oldValue) {
						this.getCVCHead(newValue)
					}
				},
				immediate: true,
			},
			show: {
				handler(show) {
					this.is = {
						...this.is,
						showingModal: show,
					}
				},
			},
			unitList: {
				deep: true,
				handler() {
					this.getUnitOptions()
				},
				immediate: true,
			},
		},
		created() {
			this.getDistrictList()
			this.getUnitList()
			this.getHubAreaList()
		},
		mounted() {
			this.reset()
		},
		methods: {
			...mapActions({
				createDisbursePremiumPoint: "PREMIUMPOINT/createDisbursePremiumPoint",
				getCVCHead: "PREMIUMPOINT/getCVCHead",
				getDistrictList: "OTHERS/getDistrictList",
				getHubAreaList: "OTHERS/getHubAreaList",
				getUnitList: "OTHERS/getUnitList",
			}),
			close() {
				this.is = {
					...this.is,
					loading: false,
				}

				this.$emit("close")
			},
			getDistrictOptions() {
				this.districtOptions = map(this.districtList, (district) => {
					return {
						text: district.district,
						value: district.districtId,
					}
				})

				this.districtOptions = reject(this.districtOptions, (district) => {
					return !district.text || !district.value
				})
			},
			getHubAreaOptions() {
				this.hubAreaOptions = map(this.hubAreaList, (hubArea) => {
					return {
						text: hubArea.areaCode,
						value: hubArea.areaCode,
					}
				})

				this.hubAreaOptions = reject(this.hubAreaOptions, (hubArea) => {
					return !hubArea.text || !hubArea.value
				})
			},
			getUnitOptions() {
				this.unitOptions = map(this.unitList, (unit) => {
					return {
						text: unit.unit,
						value: unit.unit,
					}
				})

				this.unitOptions = reject(this.unitOptions, (unit) => {
					return !unit.text || !unit.value
				})
			},
			reset() {
				this.localDisburse = Object.assign(
					{},
					{
						districtId: null,
						endDate: null,
						hubArea: null,
						startDate: null,
						unit: null,
					},
				)
			},
			save() {
				const self = this
				this.is = {
					...this.is,
					loading: true,
				}

				const districtName = findWhere(this.districtOptions, { value: this.localDisburse.districtId }).text

				if (!this.cvcHead?.premiumCvcDistrictId) {
					this.$swal(
						`${this.$t("Failed", "Failed")}!`,
						`${districtName} ${this.$t("district's CVC/CVC Head not found", "district's CVC/CVC Head not found")}.`,
						"error",
					)

					this.is = {
						...this.is,
						loading: false,
					}
				} else if (!this.cvcHead?.accountNumber) {
					this.$swal(
						`${this.$t("Failed", "Failed")}!`,
						`${districtName} ${this.$t("district's CVC/CVC Head bank account not found", "district's CVC/CVC Head bank account not found")}.`,
						"error",
					)

					this.is = {
						...this.is,
						loading: false,
					}
				} else {
					this.$refs.form_add_disburse_premium_point.validate().then((valid) => {
						if (valid) {
							self.createDisbursePremiumPoint({
								...self.localDisburse,
							})
								.then(() => {
									self.close()
								})
								.catch(() => {
									self.close()
								})
						} else {
							self.is = {
								...self.is,
								loading: false,
							}
						}
					})
				}
			},
		},
	}
</script>
